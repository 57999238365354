import '../../assets/css/wallet.css';

import { Component } from 'react';
import DataTable  from 'react-data-table-component';

import {formatNumber, navigateTo, showNotification} from "../../lib/helper";
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../lib/api";
import DateRangePicker from "../../lib/custom/DateRangePicker";
import moment from "moment";

function formatContact(data) {
	let zl = '', fb = '', tl = '';
	if(data.zl){
		zl = `<a class="social-contact" target="_blank" href="https://zalo.me/${data.zl}"><img style="width: 25px" src="images/icon/zl.webp" alt=""></a>`
	}
	if(data.fb){
		fb = `<a class="social-contact" target="_blank" href="${data.fb}"><img style="width: 25px" src="images/icon/fb.png" alt=""></a>`
	}
	if(data.tl_username){
		tl = `<a class="social-contact" target="_blank" href="https://t.me/${data.tl_username}"><img style="width: 25px" src="images/icon/tl.png" alt=""></a>`
	}
	return `<div>${zl}${fb}${tl}</div>`
}

class Agency extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			per_page: 10,
			data: [],
			total: 0,

			sort_selector: 'created_at',
			sort_direction: 'desc',
		};
		this.columns = [
			{
				name: 'TÊN ĐẠI LÝ',
				selector: 'full_name',
				sortable: true,
				allowOverflow: true
			},
			{
				name: 'NICKNAME',
				selector: 'username',
				data: 'username',
				sortable: true,
				allowOverflow: true
			},
			{
				name: 'ĐIỆN THOẠI',
				selector: 'phone',
				data: 'phone',
				sortable: false,
				allowOverflow: true
			},
			{
				name: 'KHU VỰC',
				selector: 'agency_area',
				data: 'agency_area',
				sortable: true,
				allowOverflow: true,
			},
			{
				name: 'LIÊN HỆ',
				selector: 'zl',
				sortable: false,
				format: (row, index) => (<div dangerouslySetInnerHTML={{ __html: formatContact(row) }} />),
				allowOverflow: true
			},
			{
				name: 'THAO TÁC',
				selector: 'value',
				sortable: false,
				right: true,
				format: (row, index) => (<button className='btn btn-sm btn-warning'
												 onClick={navigateTo('transfer', {username: row.username}).bind(this)}><i className='fas fa-exchange-alt' /> Chuyển</button>),
				allowOverflow: true
			},
		];
	}

	componentDidMount() {
		this.loadAgency();
	}

	loadAgency = () => {
		let { page, per_page, sort_selector, sort_direction } = this.state;
		api.getAgency({ limit: per_page, page, sort_selector, sort_direction }).then(({ data, success }) => {
				if(success){
					this.setState({ data: data.data, total: data.total });
				}
			})
	}

	handlePageChange = (page) => {
		this.setState({ page }, this.loadAgency)
	}

	handlePerRowsChange = (per_page) => {
		this.setState({ per_page }, this.loadAgency)
	}

	onSort = (column, sortDirection) => {
		this.setState({ sort_selector: column.data || column.selector, sort_direction: sortDirection }, this.loadAgency)
	}

	render() {
		let { data, total } = this.state;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-efb97944="" data-v-b0e45e8c="" id="binaryPage" sticky-container="">
						<section data-v-efb97944="" className="container">
							<div data-v-efb97944="" className="balance">
								<div data-v-efb97944="" className="wrapBalance">
									<div data-v-efb97944="" className="boxBalance">
										<div data-v-7ab9e0ba="" data-v-efb97944="" className="history mt-3">
											<div className='headerContent d-flex flex-lg-row flex-column justify-content-between align-items-center mb-3'>
												<h4 data-v-57eecd1c="" className="history-title color-white">
													<svg data-v-57eecd1c="" xmlns="http://www.w3.org/2000/svg"
														 width="40.241" height="43.901" viewBox="0 0 40.241 43.901"
														 className="icon">
														<g data-v-57eecd1c="" id="Group_21226" data-name="Group 21226"
														   transform="translate(76.76 -165.929)">
															<g data-v-57eecd1c="" id="trophy"
															   transform="translate(-77.26 166.429)">
																<path data-v-57eecd1c="" id="Path_31718"
																	  data-name="Path 31718"
																	  d="M8.65,12.97H7.32A5.32,5.32,0,0,1,2,7.65V1H8.65"
																	  fill="none" stroke="#fff" strokeMiterlimit="10"
																	  strokeWidth="3"/>
																<path data-v-57eecd1c="" id="Path_31719"
																	  data-name="Path 31719"
																	  d="M25,12.97h1.33a5.32,5.32,0,0,0,5.32-5.32V1H25"
																	  transform="translate(7.591)" fill="none"
																	  stroke="#fff" strokeMiterlimit="10"
																	  strokeWidth="3"/>
																<line data-v-57eecd1c="" id="Line_1501"
																	  data-name="Line 1501" y1="9"
																	  transform="translate(20.26 25.571)" fill="none"
																	  stroke="#fff" strokeMiterlimit="10"
																	  strokeWidth="3"/>
																<path data-v-57eecd1c="" id="Path_31720"
																	  data-name="Path 31720"
																	  d="M18.97,26.271h0A11.97,11.97,0,0,1,7,14.3V1H30.941V14.3a11.97,11.97,0,0,1-11.97,11.97Z"
																	  transform="translate(1.65)" fill="none"
																	  stroke="#f7a62f" strokeLinecap="square"
																	  strokeMiterlimit="10" strokeWidth="3"/>
																<path data-v-57eecd1c="" id="Path_31721"
																	  data-name="Path 31721"
																	  d="M27.621,32.65H9A6.65,6.65,0,0,1,15.65,26h5.32A6.65,6.65,0,0,1,27.621,32.65Z"
																	  transform="translate(2.31 9.251)" fill="none"
																	  stroke="#fff" strokeLinecap="square"
																	  strokeMiterlimit="10" strokeWidth="3"/>
															</g>
														</g>
													</svg> Danh sách đại lý
												</h4>
											</div>
											<div data-v-7ab9e0ba="" className="history-body">
												<div data-v-7ab9e0ba="" className="history-content">
													<div data-v-7ab9e0ba="" className="box-result" style={{ borderRadius: '3px' }}>
														<DataTable
															columns={this.columns}
															data={data}
															pagination
															paginationServer
															paginationTotalRows={total}
															onChangeRowsPerPage={this.handlePerRowsChange}
															onChangePage={this.handlePageChange}
															sortServer
															onSort={this.onSort}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Agency);
