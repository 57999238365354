import {takeLatest, put, call} from 'redux-saga/effects';
import store from '../store';

import {
	fetchingNotification,
	fetchingNotificationSuccess
} from '../actions';
import api from "../../lib/api";

export function* watchFetchingNotification() {
	yield takeLatest(fetchingNotification, fetchingNotificationSaga)
}

function* fetchingNotificationSaga() {
	try {
		let {limit, page} = store.getState().transactionReducer || {};
		let {data: response_data, success} = yield call(api.getNotification, {page, limit});
		if (success) {
			let {un_read_count, total, data} = response_data;
			yield put(fetchingNotificationSuccess({un_read_count, total, notification: data}))
		}
	} catch (error) {
	}
}
