import '../../assets/css/main.css';
import { Component } from 'react';
import LeftContent from "./LeftContent";
import Analysis from "./Analysis";
import {connect} from "react-redux";
import {updateAppConfigAction} from "../../redux/actions";
import {getChartHeight} from "../../lib/trade_default_data";
import api from "../../lib/api";
import { isDesktop} from "../../lib/helper";

class Trading extends Component{
	constructor(props) {
		super(props);
		this.state = {
			trading_data: [],
			psychological_indicators: 0,
		}
	}

	componentDidMount() {
		if(!this.props.memberReducer?.login_token){
			return this.props.history.push('/login');
		}

		getChartHeight();
		api.getTradingData()
			.then((data) => {
				data = (data || []);
				this.setState({ trading_data: data });
			});
	}

	onCloseModal = () => {
		this.props.updateAppConfigAction({ win_value: 0 });
	}

	render() {
		if(!this.props.memberReducer?.login_token){
			return null;
		}
		let { trading_data } = this.state;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-5b2de64c="" data-v-b0e45e8c="" id="binaryPage">
						<section data-v-5b2de64c="" className="content position-relative">
							<div data-v-5b2de64c="" className={ 'h-100 ' + ( isDesktop() ? 'd-flex flex-lg-row flex-md-row' : '' ) }>
								<LeftContent trading_data={trading_data} />
								<Analysis trading_data={trading_data}  />
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	updateAppConfigAction
})(Trading);
