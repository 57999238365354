import '../../assets/css/register.css';

import { Component } from 'react';

import { withRouter } from "react-router-dom";
import api from "../../lib/api";
import {setItem, getItem, KEY} from "../../lib/database";
import {getJSONFromUrl, navigateTo, showNotification} from "../../lib/helper";
import {connect} from "react-redux";

class Register extends Component{
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			username: '',
			ref_username: '',
			ref_username_disabled: false,
			is_show_password: false
		};
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
	}

	componentDidMount() {
		let {r} = getJSONFromUrl();
		if(r){
			setItem(KEY.REF_USERNAME, r);
		} else {
			r = getItem(KEY.REF_USERNAME);
		}
		this.setState({ ref_username: r, ref_username_disabled: !!r });
	}

	onKeyPress = (e) => {
		if(e.code === 'Enter'){
			this.onSignUp(e);
		}
	}

	onSignUp = (e) => {
		let {email, password, username, ref_username} = this.state;
		let isDisableSignButton = !email || email.length === 0 || !password || password.length === 0 || !username || username.length === 0;
		if(isDisableSignButton){
			return false;
		}
		api.register({ email, password, username, ref_username })
			.then(({data, message, success}) => {
				showNotification({message, type: success ? 'success' : 'danger'});
				if(!success){
					return null;
				}
				navigateTo('login', { email: email }).bind(this)(e);
			});
	}

	onToggleShowPassword = () => {
		this.setState({ is_show_password: !this.state.is_show_password })
	}

	onPreventUsername = (e) => {
		if (!e.key.match(/[a-zA-Z0-9,]/)) {
			e.preventDefault();
		}
	}

	onChangeUsername = (e) => {
		let username = (e.target.value || '')
			.toLowerCase()
			.replace(/[.-]/g, '')
			.replace(/[^\w ]+/g, '')
			.replace(/ +/g, '');
		this.setState({ username: username });
	}

	render() {
		let {email, password, username, ref_username, ref_username_disabled, is_show_password} = this.state;
		console.log('ref_username_disabled', ref_username_disabled);
		let isDisableSignButton = !email || email.length === 0 || !password || password.length === 0 || !username || username.length === 0;
		let { asset_logo } = this.props.appReducer;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-682ccdfa="" data-v-b0e45e8c="" className="boxFix">
						<div data-v-682ccdfa="" className="boxAuthentication show">
							<div data-v-682ccdfa=""
								 className="contentAuthentication d-flex flex-column align-items-center justify-content-between">
								<div data-v-682ccdfa=""
									 className="header d-flex align-items-center justify-content-between w-100"><a
									style={{ backgroundImage: `url(${asset_logo})` }}
									data-v-682ccdfa="" href="/" className="logoLogin mb-0 router-link-active">BONAX</a>
									<div data-v-682ccdfa="" onClick={navigateTo('/').bind(this)} className="close-page"><span data-v-682ccdfa=""
																						 className="pe pe-7s-close" />
									</div>
								</div>
								<div data-v-682ccdfa="" className="formWapper w-100">
									<form data-v-682ccdfa="" className="mt-5"><h2 data-v-682ccdfa=""
																									  className="font-24 color-white font-weight-bold mb-5">Tạo
										tài khoản BONAX</h2>
										<div data-v-682ccdfa=""
											 className="md-field md-theme-default md-has-placeholder"><label
											data-v-682ccdfa="" htmlFor="md-input-l82oqjdho">
											Địa chỉ Email
											<small data-v-682ccdfa="">*</small></label> <input data-v-682ccdfa=""
																							   type="text"
																							   value={email}
																							   onKeyPress={this.onKeyPress}
																							   onChange={this.onChangeInput('email')}
																							   id="md-input-l82oqjdho"
																							   placeholder="Điền Email"
																							   className="md-input" />
											<small data-v-682ccdfa=""
												   className="md-error" />   </div>
										<div data-v-682ccdfa=""
											 className="md-field md-theme-default md-has-placeholder md-has-password"
											 md-has-password=""><label data-v-682ccdfa="" htmlFor="md-input-j4h4qfszm">
											Mật khẩu
											<small data-v-682ccdfa="">*</small></label> <input data-v-682ccdfa=""
																							   type={is_show_password ? 'text' : 'password'}
																							   value={password}
																							   onKeyPress={this.onKeyPress}
																							   onChange={this.onChangeInput('password')}
																							   id="md-input-j4h4qfszm"
																							   placeholder="Điền mật khẩu"
																							   maxLength="20"
																							   className="md-input" />
											<small data-v-682ccdfa="" className="md-error" /> <span
											className="md-count">{password.length} / 20</span>
											<button type="button"
													onClick={this.onToggleShowPassword}
													className="md-button md-icon-button md-dense md-input-action md-toggle-password md-theme-default"
													tabIndex="-1">
												<div className="md-ripple">
													<div className="md-button-content">
														<i className="md-icon md-icon-font md-icon-image md-theme-default">
															{is_show_password ?
																<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
																	<path d="M0 0h24v24H0z" fill="none"/> <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
																</svg>
																:<svg height="24" viewBox="0 0 24 24" width="24"
															 xmlns="http://www.w3.org/2000/svg">
															<path
																d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
																fill="none"/>
															<path
																d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
														</svg>}
													</i>
													</div>
												</div>
											</button></div>
										<div data-v-682ccdfa=""
											 className="md-field md-theme-default md-has-placeholder nickName"><label
											data-v-682ccdfa="" htmlFor="md-input-31z20oa47">
											Biệt danh
											<small data-v-682ccdfa="">*</small></label> <input data-v-682ccdfa=""
																							   type="text"
																							   autoComplete={'off'}
																							   value={username}
																							   onKeyDown={this.onPreventUsername}
																							   onKeyPress={this.onKeyPress}
																							   onChange={this.onChangeUsername}
																							   id="md-input-31z20oa47"
																							   placeholder="Điền Nickname"
																							   maxLength="20"
																							   className="md-input"/>
											<span className="md-count">{username.length} / 20</span>
										</div>
										<div data-v-682ccdfa=""
											 className="md-field md-theme-default md-has-placeholder nickName"><label
											data-v-682ccdfa="" htmlFor="md-input-31z20oa47">Mã giới thiệu</label>
											<input data-v-682ccdfa=""
												   type="text"
												   autoComplete={'off'}
												   value={ref_username}
												   onKeyPress={this.onKeyPress}
												   disabled={ref_username_disabled}
												   onChange={this.onChangeInput('ref_username')}
												   id="md-input-31z20oa47"
												   placeholder="Điền mã giới thiệu"
												   className="md-input"/>
										</div>
										<p data-v-682ccdfa="" className="text-center">
											<button data-v-f99ecb42="" type='button' onClick={this.onSignUp} disabled={isDisableSignButton}
													className={'btn-large btn-radius w-100 mt-4 ' + (isDisableSignButton ? 'btn-secondary btn' : 'button wbtn')}> Đăng ký</button>
										</p>
									</form>
								</div>
								<p data-v-682ccdfa="" className="color-white">
									Đã có tài khoản?
									<a data-v-682ccdfa="" href="#" onClick={navigateTo('/login').bind(this)} className="colorBlue4"> Đăng nhập vào Tài khoản
										của bạn</a></p></div>
							<div data-v-682ccdfa="" tabIndex="-1" role="dialog" aria-hidden="true"
								 className="modal fade">
								<div className="modal-dialog">
									<div className="modal-content">
										<div className="panel" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps)(withRouter(Register));
