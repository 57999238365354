import '../../assets/css/challenge.css';

import { Component } from 'react';
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import $ from "jquery";
import {setClassNameCondition, isDesktop} from "../../lib/helper";
import Item from "./partial/item";
import DetailModal from "./partial/detail_modal";
import DetailModalMobile from "./partial/detail_modal_mobile";
import api from "../../lib/api";
import ItemRank from "./partial/item_rank";

const backgroundChallenges = [
	'images/bg_challenge_1.png',
	'images/bg_challenge_2.png',
	'images/bg_challenge_3.jpeg',
	'images/bg_challenge_4.png',
];

class Challenge extends Component{
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			is_show_detail: false,
			challenges: [],
			challenge_detail: null,
			challenge_status: 'live',
		};
	}

	componentDidMount() {
		this.loadChallenge();
	}

	loadChallenge = () => {
		api.getChallenge(this.state.challenge_status)
			.then(({ data, success }) => {
				success && this.setState({ challenges: data })
			});
	}

	onChangeChallengeStatus = (status) => () => {
		this.setState({ challenge_status: status }, this.loadChallenge)
	}

	onShowDetail = (challenge_detail) => () => {
		this.setState({ is_show_detail: !this.state.is_show_detail, challenge_detail }, () => {
			if(this.state.is_show_detail){
				$('body').addClass('modal-open');
			} else {
				$('body').removeClass('modal-open');
			}
		})
	}

	render() {
		let { is_show_detail, challenges, challenge_detail, challenge_status } = this.state;
		let { window_width, asset_challenge_banner } = this.props.appReducer;
 		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					{isDesktop() ?
					<div data-v-ec313c08="" data-v-b0e45e8c="">
						<div data-v-ec313c08="" className="deskTopSection">
							<div data-v-ec313c08="" className="bgBanner"
								 style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${asset_challenge_banner}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '30% center; background-attachment: fixed' }}>
								<div data-v-ec313c08="" className="overlayBanner"/>
								<div data-v-ec313c08="" className="content ">
									<div data-v-ec313c08="" className="w-100 h-100 position-relative d-flex">
										<div data-v-ec313c08="" className="contentLeft 'w-500px min-w500px ">
											<div data-v-ec313c08=""
												 className="d-flex flex-column justify-content-center h-100 "><img
												data-v-ec313c08=""
												src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA8CAYAAADc1RI2AAAABHNCSVQICAgIfAhkiAAABCdJREFUaEPtWjFuU0EQfc8UdOAEAiXkBCQdSgNpaAknIJyA+ATgEzg+AeQE0CMRUiBEhXMCQpkUcaADpAx6Ztf8fPvb3v1rJw5/JMuW/u7svn0zszPjTzMzXG7Z438FkiSLCDWzhwB2AeyR1O9zFU/MxHsOnlCBnA3BwcQET6iYrJhMdgLB1hc8oTLXZGSNVBRMTPCEOWXyAMAdAMsk9XtA5jQZ2ALQAtBWWvcWwGMAT0jq92UBuQ3gOYCGQL4E8ALADsnNSwTSW+i6QN4F0AFwHcA6yQ95oPNmrhni9kmu9JJyM/P2K/QCesY35wlkZq+Ctkqy0688zEwMPgBwAuAlybZndB5AmlnduZ0IkzRJyhWRBalBr10Q0jOZsBjVt0xa/nqhSi2R4QCp/FsBIAySBkkFnp4M1JBmtuHAykfzctFA5ve3LzJkotkHowplnYwY9N9PLyCTTQdGrtYhKVcbkEKQ2ZHz4JPDwBWa67DBZiY2v7jTWh2lcNrP3JX3FcA3krK0sTIRk9IySY47drUEA2KsqgKZ80tFrHujctwERI1VYWY+J22T9HfiyHkhTAYrH7vjiAFmptigGDE0BY2Ors4nffA5ILkcsb/SUzJB5ztJf/GP1Tsxkw6o7qHCRH7saiUHZEy1sGIqxaQD2U/kZ81mhkVtpbDALw3SAfV12pn8sCRJY6eb2RsASjmDWJTiIHN1IJWov3LVipz/TJ44drcRA8zMr/ldQaeoTVOkOhikA6pqRbmsWFXNNjRnjMAzMCVXHz4jqbWDJAqkA+rvTX2L0eRAXTqpf9MUSfv1YRDCGHP1C7giVdm/EgQBTGq6GRPVksF+mD2IaCYdmzphdfiGdhRCTzyjU401n81MnNkk9cm8MjPzPqpH8lO1T3ZCQDrLUAtR4PxFH+WD+XVLMZlV5gKEwKpRLZEJi2V91DUbaFw7n5O562rQx8ueq/CHNrtDDk9jk4HM+KrCvXovHmx+Tz8AXCvYqMDJCgbaoqHAkvnkqIVdhuIZUnE7DLR6MmKrx/g0IvRUmCwCbmYfAawBeETyXRlmQucmN9cJQK6R/BS60TLjK5BlTm/INePNtWIy5cF6XZW5pjzVTHStzDXlwZ6Hub4HcN9VK5+nAaZI58x8cpag8mtVIGNO/2ar+7DG06cAN/ivZJpE1cEp7O1vq7VPGgtJqo/kPllvde9epanBVfql31PY9m+rNU8aC0laKknMdanVXSFtN5C5kewa0PllXE8BtDTIaQDs16aJgJYGeat13CF7zaypiJk1jxo3/AsQUWuUArnU6m7W/vrhVOWncblMMCoF8nbr+APY69RNV4yNw8ZC/5WV0MWiQdZb3fpVWjd0wZjxCkJHW4vR7ypEg9R9eIWm7vZM5HBrMXqv0RMVVWvo/bU9EzlsLEbfv38AyFyAsdSZp9gAAAAASUVORK5CYII=" />
												<div data-v-ec313c08="" className="titleChallenge text-capitalize">Giải
													đấu
												</div>
												<div data-v-ec313c08="" className="shortDesc">Tham gia và giành giải
													thưởng lớn.
												</div></div>
										</div>
									</div>
								</div>
							</div>
							<div data-v-2f4ce6a2="" data-v-ec313c08="">
								<div data-v-2f4ce6a2="" tabIndex="-1" role="dialog" aria-hidden="true"
									 className="modal fade modal-info detailPopup detailChallenge">
									<div data-v-2f4ce6a2="" role="document" className="modal-dialog">
										<div data-v-2f4ce6a2="" className="rowFooter">
											<div data-v-2f4ce6a2="" className="container"/>
										</div>
									</div>
								</div>
							</div>
							<div data-v-ec313c08="" className="bgBot"
								 style={{backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('images/backgroundBottom.jpeg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '30% center; background-attachment: fixed' }}>
								<div data-v-ec313c08="" className="containercl">
									<div data-v-ec313c08="" className="d-flex align-items-center divBot"><img
										data-v-ec313c08="" alt=''
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAqCAYAAADS4VmSAAAABHNCSVQICAgIfAhkiAAABIVJREFUWEetV79vHFUQ/j7sRALxw+dgX0FhuwfhlIgiNqLHkRBFGpKKMrcdDUosRL0O/0Dsjg5bSEQgpNgNQjRxKCiQUEx5Bu7OJMKJLTHoe35v9W5v93b34mnOvtt9883M983MIyY0M1sB8AGAdZKDCY8BJ33RA7gPYB/ADZL6bGznAUBOlYGE5GZTBOcB4AjAa97x5aaZeB4AivZjcQDAov9bmVhqwomJAJjZDIBHAPQphwdmtgvgiudD7VI0BuCd3wWwBmCPpNQAM9P/X8ff1eFDIwCe+XKulKv2y4reA1A2+iIkyVYd53qmFgAf9S0AHX/wQwDX84QzM3OHkrXObQJAeleqFfVtkhv5CM1sGcADAH+QVIZqWSXSqOHI+UqZzMxMoG4CuEMyZKoSRB0AIXo1mpHIgwczkyoUeaNeUAdAZV09R0TAI5IiY2ZeHSKmZDpi5wVA/FCmMllGmREvxA/Ni5H+UBsAgFZZh4sIeEByKXJ+HYBkG2wExBAAM1sMuo4OyVouydtlrDIzTcO3Q6RmplGtd1USyVa/yVbjcmQAfK1uklzN1TCkd2yDMTMxPy0A6MpiZgKvXqLGJaK6HSIGEFg8hFAPmZleWqhiuA9CUYfpqEG1EZxFWcqk6gCYWVyroYlWNHgqxV3ygErsh5iecEMsAIg7ndAr4m2/aAicXtwhqYEzZDNpf+YC/7tFw9FhcqmUI2WcYsRgN1yUMr/rxY72NP3yKphL+8uk3aXeM7vTTS5VdsCoszrFCEAgR1YXDypEu09S2chHvniR9oBnLIcB+4ed2ct1yhNxygEIMitsFGUHzqe9fTKTlnvsmXFpkLTceB5nZqaAJNOrAhA2mRH2lx3STvsd0EYkZ2brNXkQsr4uAOrhSmNpp8sDaae9A9DJcsgMGJycZWHsPSFS3ZYAVA6b2IuI9wJN/b3Q6mQhWt92BEBoJb3SDMjpn0nLXTxeT/srUzTJttSquBAR35WgkgPzaW8Aiui2Tbhtxy2iY2y325kdaunxs3kAgZGFKphP+2ukadttZsakm7QKF5i8CkIbLux07fTvDZBatZraQbczm43m8HK0vOirlkoQ+nPhraad9nZBd+FobGa8epi0hppY/v4QZkHgga7aQ/1c9Sez6dYIRJEiot3RlTwAyGZ+/m7X3ug5mU5ieQCR/rPVPd4HQhZ246WkrOnUARQD8PNF8lXTywgfAxAXpHX1hE2SN+TkeTgArwRPPDnXtN0iKeI7y++E4Xaj37TJJGV9v04G1JD6nRmROzjXbqjLTdaqR7bi3Ha0/cWPTztf/nz8sCkRzbDT7bRE6JD2EecjGYi0qkyIEyrH4NP7x99u/vL0Wp2o/aGPv7/26ndvzU196N8pXGhKAegH3x+0K7ge8Mm9J092fjt9uQrEi9M8/eajVy68OTcVHh17V6xzMZFEBWThq19P8Nnev3h8UqzMd96YxudXXoJ3vuVv0mMXlEoAubKs/XVs7//w6PTde7+f4J9nZ0Dk8L3Fi93Vhemf/DIrKVduRmNLUJXq8/r9f4pCZc57QKHRAAAAAElFTkSuQmCC"
										width="37" height="48" className="mr-3" />
										<a data-v-ec313c08=""
										   onClick={this.onChangeChallengeStatus('live')}
										   className={setClassNameCondition(challenge_status === 'live', 'active', '', 'challengeType text-capitalize pointer')}>
											Giải đấu <span data-v-ec313c08="" className="middle ml-1 mr-2">/</span>
										</a>
										<a data-v-ec313c08=""
										   onClick={this.onChangeChallengeStatus('end')}
										   className={setClassNameCondition(challenge_status === 'end', 'active', '', 'challengeType text-capitalize pointer')}>
											Đã kết thúc
										</a>
									</div>
									<div data-v-ec313c08="">
										<div data-v-565d56de="" data-v-ec313c08="">
											<div data-v-565d56de="" className="row">
												{(challenges || []).map((item, index) => {
													return (<div key={index} data-v-565d56de="" className="colMix mb-4">
														{item.status === 'live' ?
															<Item bg={backgroundChallenges[index % 4]} onShowDetail={this.onShowDetail(item)} data={item}/>
														: <ItemRank bg={backgroundChallenges[index % 4]} onShowDetail={this.onShowDetail(item)} data={item}/>}
													</div>)
												})}
											</div>
											<div data-v-2f4ce6a2="" data-v-565d56de="">
												<div data-v-2f4ce6a2="" tabIndex="-1" role="dialog"
													 className={setClassNameCondition(is_show_detail, 'show', '', 'modal fade modal-info detailPopup detailChallenge')}
													 style={{ display: is_show_detail ? 'block' : 'none', paddingLeft: '0px' }}>
													{is_show_detail && <DetailModal detail={challenge_detail} onClose={this.onShowDetail()} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
						:
						<div data-v-ec313c08="" data-v-b0e45e8c="">
							<div data-v-ec313c08="" className="backgroundDark">
								<div data-v-ec313c08="" className="text-center">
									<ul data-v-ec313c08="" id="pills-tab" role="tablist"
										className="nav nav-pills menuHeaderLink">
										<li data-v-ec313c08="" className="nav-item">
											<a data-v-ec313c08=""
											   onClick={this.onChangeChallengeStatus('live')}
											   className={setClassNameCondition(challenge_status === 'live', 'active', '', 'nav-link text-capitalize')}>Giải đấu</a>
										</li>
										<li data-v-ec313c08="" className="nav-item">
											<a data-v-ec313c08=""
											   onClick={this.onChangeChallengeStatus('end')}
											   className={setClassNameCondition(challenge_status === 'end', 'active', '', 'nav-link text-capitalize')}>Đã kết thúc</a>
										</li>
									</ul>
								</div>
							</div>
							<div data-v-ec313c08="">
								<div data-v-565d56de="" data-v-ec313c08="" className="tab">
									<div data-v-565d56de="" className="allChallenges">
										{challenge_status === 'live' && <div data-v-565d56de="" className="bgTop text-center position-relative">
											<img data-v-565d56de="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA8CAYAAADc1RI2AAAABHNCSVQICAgIfAhkiAAABCdJREFUaEPtWjFuU0EQfc8UdOAEAiXkBCQdSgNpaAknIJyA+ATgEzg+AeQE0CMRUiBEhXMCQpkUcaADpAx6Ztf8fPvb3v1rJw5/JMuW/u7svn0zszPjTzMzXG7Z438FkiSLCDWzhwB2AeyR1O9zFU/MxHsOnlCBnA3BwcQET6iYrJhMdgLB1hc8oTLXZGSNVBRMTPCEOWXyAMAdAMsk9XtA5jQZ2ALQAtBWWvcWwGMAT0jq92UBuQ3gOYCGQL4E8ALADsnNSwTSW+i6QN4F0AFwHcA6yQ95oPNmrhni9kmu9JJyM/P2K/QCesY35wlkZq+Ctkqy0688zEwMPgBwAuAlybZndB5AmlnduZ0IkzRJyhWRBalBr10Q0jOZsBjVt0xa/nqhSi2R4QCp/FsBIAySBkkFnp4M1JBmtuHAykfzctFA5ve3LzJkotkHowplnYwY9N9PLyCTTQdGrtYhKVcbkEKQ2ZHz4JPDwBWa67DBZiY2v7jTWh2lcNrP3JX3FcA3krK0sTIRk9IySY47drUEA2KsqgKZ80tFrHujctwERI1VYWY+J22T9HfiyHkhTAYrH7vjiAFmptigGDE0BY2Ors4nffA5ILkcsb/SUzJB5ztJf/GP1Tsxkw6o7qHCRH7saiUHZEy1sGIqxaQD2U/kZ81mhkVtpbDALw3SAfV12pn8sCRJY6eb2RsASjmDWJTiIHN1IJWov3LVipz/TJ44drcRA8zMr/ldQaeoTVOkOhikA6pqRbmsWFXNNjRnjMAzMCVXHz4jqbWDJAqkA+rvTX2L0eRAXTqpf9MUSfv1YRDCGHP1C7giVdm/EgQBTGq6GRPVksF+mD2IaCYdmzphdfiGdhRCTzyjU401n81MnNkk9cm8MjPzPqpH8lO1T3ZCQDrLUAtR4PxFH+WD+XVLMZlV5gKEwKpRLZEJi2V91DUbaFw7n5O562rQx8ueq/CHNrtDDk9jk4HM+KrCvXovHmx+Tz8AXCvYqMDJCgbaoqHAkvnkqIVdhuIZUnE7DLR6MmKrx/g0IvRUmCwCbmYfAawBeETyXRlmQucmN9cJQK6R/BS60TLjK5BlTm/INePNtWIy5cF6XZW5pjzVTHStzDXlwZ6Hub4HcN9VK5+nAaZI58x8cpag8mtVIGNO/2ar+7DG06cAN/ivZJpE1cEp7O1vq7VPGgtJqo/kPllvde9epanBVfql31PY9m+rNU8aC0laKknMdanVXSFtN5C5kewa0PllXE8BtDTIaQDs16aJgJYGeat13CF7zaypiJk1jxo3/AsQUWuUArnU6m7W/vrhVOWncblMMCoF8nbr+APY69RNV4yNw8ZC/5WV0MWiQdZb3fpVWjd0wZjxCkJHW4vR7ypEg9R9eIWm7vZM5HBrMXqv0RMVVWvo/bU9EzlsLEbfv38AyFyAsdSZp9gAAAAASUVORK5CYII=" />
											<h1 data-v-565d56de="" className="text-capitalize mb-1">Giải đấu</h1>
											<span data-v-565d56de="" className="px-2">Tham gia và giành giải thưởng lớn.</span>
										</div>}
										<div data-v-565d56de="" className={setClassNameCondition(challenge_status==='end', 'divChallenge','','bgBot')}
											 style={{ backgroundImage: " linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://wefinex.azureedge.net/files/bo/images/challenges/backgroundBottom.jpg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '30% center', backgroundAttachment: 'fixed' }}>
											<div data-v-565d56de="" className="container">
												{challenge_status==='live' ?
												<div data-v-565d56de="" className="d-flex align-items-center mb-35">
													<img
													data-v-565d56de=""
													src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAqCAYAAADS4VmSAAAABHNCSVQICAgIfAhkiAAABIVJREFUWEetV79vHFUQ/j7sRALxw+dgX0FhuwfhlIgiNqLHkRBFGpKKMrcdDUosRL0O/0Dsjg5bSEQgpNgNQjRxKCiQUEx5Bu7OJMKJLTHoe35v9W5v93b34mnOvtt9883M983MIyY0M1sB8AGAdZKDCY8BJ33RA7gPYB/ADZL6bGznAUBOlYGE5GZTBOcB4AjAa97x5aaZeB4AivZjcQDAov9bmVhqwomJAJjZDIBHAPQphwdmtgvgiudD7VI0BuCd3wWwBmCPpNQAM9P/X8ff1eFDIwCe+XKulKv2y4reA1A2+iIkyVYd53qmFgAf9S0AHX/wQwDX84QzM3OHkrXObQJAeleqFfVtkhv5CM1sGcADAH+QVIZqWSXSqOHI+UqZzMxMoG4CuEMyZKoSRB0AIXo1mpHIgwczkyoUeaNeUAdAZV09R0TAI5IiY2ZeHSKmZDpi5wVA/FCmMllGmREvxA/Ni5H+UBsAgFZZh4sIeEByKXJ+HYBkG2wExBAAM1sMuo4OyVouydtlrDIzTcO3Q6RmplGtd1USyVa/yVbjcmQAfK1uklzN1TCkd2yDMTMxPy0A6MpiZgKvXqLGJaK6HSIGEFg8hFAPmZleWqhiuA9CUYfpqEG1EZxFWcqk6gCYWVyroYlWNHgqxV3ygErsh5iecEMsAIg7ndAr4m2/aAicXtwhqYEzZDNpf+YC/7tFw9FhcqmUI2WcYsRgN1yUMr/rxY72NP3yKphL+8uk3aXeM7vTTS5VdsCoszrFCEAgR1YXDypEu09S2chHvniR9oBnLIcB+4ed2ct1yhNxygEIMitsFGUHzqe9fTKTlnvsmXFpkLTceB5nZqaAJNOrAhA2mRH2lx3STvsd0EYkZ2brNXkQsr4uAOrhSmNpp8sDaae9A9DJcsgMGJycZWHsPSFS3ZYAVA6b2IuI9wJN/b3Q6mQhWt92BEBoJb3SDMjpn0nLXTxeT/srUzTJttSquBAR35WgkgPzaW8Aiui2Tbhtxy2iY2y325kdaunxs3kAgZGFKphP+2ukadttZsakm7QKF5i8CkIbLux07fTvDZBatZraQbczm43m8HK0vOirlkoQ+nPhraad9nZBd+FobGa8epi0hppY/v4QZkHgga7aQ/1c9Sez6dYIRJEiot3RlTwAyGZ+/m7X3ug5mU5ieQCR/rPVPd4HQhZ246WkrOnUARQD8PNF8lXTywgfAxAXpHX1hE2SN+TkeTgArwRPPDnXtN0iKeI7y++E4Xaj37TJJGV9v04G1JD6nRmROzjXbqjLTdaqR7bi3Ha0/cWPTztf/nz8sCkRzbDT7bRE6JD2EecjGYi0qkyIEyrH4NP7x99u/vL0Wp2o/aGPv7/26ndvzU196N8pXGhKAegH3x+0K7ge8Mm9J092fjt9uQrEi9M8/eajVy68OTcVHh17V6xzMZFEBWThq19P8Nnev3h8UqzMd96YxudXXoJ3vuVv0mMXlEoAubKs/XVs7//w6PTde7+f4J9nZ0Dk8L3Fi93Vhemf/DIrKVduRmNLUJXq8/r9f4pCZc57QKHRAAAAAElFTkSuQmCC"
													width="30" height="40" />
													<h1 data-v-565d56de="" className="title ml-3 mb-0">Giải đấu</h1>
												</div>
													: <h1 data-v-ffed1e2a="" className="title">Đã kết thúc</h1> }
												<div data-v-565d56de="" className="row">
													{(challenges || []).map((item, index) => {
														return (<div key={index} data-v-565d56de="" className="itemChallenges col-md-6">
															{item.status === 'live' ?
																<Item bg={backgroundChallenges[index % 4]} onShowDetail={this.onShowDetail(item)} data={item}/>
																: <ItemRank bg={backgroundChallenges[index % 4]} onShowDetail={this.onShowDetail(item)} data={item}/>}
														</div>)
													})}
												</div>
											</div>
										</div>
									</div>
									<div data-v-2f4ce6a2="" data-v-565d56de="">

										<div data-v-2f4ce6a2="" tabIndex="-1" role="dialog"
											 className={setClassNameCondition(is_show_detail, 'show', '', 'modal modal-info detailPopup detailChallenge')}
											 style={{ display: is_show_detail ? 'block' : 'none', paddingLeft: '0px' }}>
											{is_show_detail && <DetailModalMobile detail={challenge_detail} onClose={this.onShowDetail()} />}
										</div>

									</div>
								</div>
							</div>
						</div> }
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Challenge));

