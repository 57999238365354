import {Component} from "react";
import Highcharts from "highcharts/highstock";
import sankey from "highcharts/modules/sankey.js";
import HighchartsReact from "highcharts-react-official";
import organization from "highcharts/modules/organization.js";
import {connect} from "react-redux";

import api from "../../../lib/api";

sankey(Highcharts);
organization(Highcharts);

class Network extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: {
				chart: {
					height: 800,
					inverted: true,
					backgroundColor: 'transparent'
				},
				title: {
					text: ''
				},
				accessibility: {
					point: {
						descriptionFormatter: function (point) {
							let nodeName = point.toNode.name,
								nodeId = point.toNode.id,
								nodeDesc = nodeName === nodeId ? nodeName : nodeName + ', ' + nodeId,
								parentDesc = point.fromNode.id;
							return point.index + '. ' + nodeDesc + ', reports to ' + parentDesc + '.';
						}
					}
				},
				series: [{
					type: 'organization',
					name: 'network',
					keys: ['from', 'to'],
					data: [
						['F0']
					],
					levels: [
						{
							level: 0,
							color: '#2177ff',
						}, {
							level: 1,
							color: '#0448b7',
						}, {
							level: 2,
							color: '#01024c',
						},{
							level: 3,
							color: '#29014c',
						}, {
							level: 4,
							color: '#cd0056',
						}, {
							level: 5,
							color: '#69002d',
						}, {
							level: 6,
							color: '#000000',
						}],
					nodes: [{
						id: 'F0',
						name: props.memberReducer?.display_name,
						image: props.memberReducer?.avatar
					}],
					colorByPoint: false,
					color: '#007ad0',
					dataLabels: {
						color: 'white'
					},
					borderColor: 'white',
					nodeWidth: 65,
					formatter: (...args) => {

					}
				}],
				tooltip: {
					enabled: !1
				},
				exporting: {
					allowHTML: true,
					sourceWidth: 800,
					sourceHeight: 600
				},
				credits: {
					enabled: !1
				},
			}
		}
	}

	componentDidMount() {
		api.getNetwork().then(({ data, success }) => {
			if(success){
				let bindData = data.filter(({ id, parent }) => id && parent).map(({ id, parent }) => ([parent, id]));
				let { options } = this.state;
				let { series } = options;
				let _option = { ...options, series: [{...series[0], data: bindData, nodes: data}] };
				this.setState({ options: _option });
			}
		});
	}

	render() {
		return (<HighchartsReact
			className="highcharts-container"
			highcharts={Highcharts}
			options={this.state.options}
		/>)
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default connect(mapStateToProps, null)(Network);
