import { Component } from 'react';
import Item from './item';

export default class Notification extends Component{
	render() {
		let { list } = this.props;
		return <div className="notification">
			<section className="ps-container scroll-area notification-wrap-list ps ps--active-y">
				<ul className="notification-list list-unstyled">
					{(list || []).map((item) => {
						return <Item key={item.id} item={item} />
					})}
				</ul>
			</section>
		</div>
	}
}
