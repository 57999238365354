import 'react-notifications-component/dist/theme.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import ReactNotification from "react-notifications-component";
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<ReactNotification />
			<Router>
				<App/>
			</Router>
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
