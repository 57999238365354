import _ax from './_ax';

export function getNotification(params){
	return _ax.get('notification', { params });
}

export function getNotificationDetail(id){
	return _ax.get('notification/' + id);
}

export function putMarkReadAllNotification(){
	return _ax.put('notification');
}
