import {Component} from 'react';
import {connect} from "react-redux";
import {fetchingNotification, fetchingNotificationSuccess } from "../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../lib/api";
import NotificationComponent from '../../lib/custom/Notification';

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notification: []
		};
	}

	componentDidMount() {
		api.getNotification({ page: 1, limit: 50 })
			.then(({ data: response_data, message, success }) => {
				if(success){
					let {un_read_count, total, data} = response_data;
					this.props.fetchingNotificationSuccess({un_read_count, total});
					this.setState({ notification: data })
				}
			});
	}

	onReadAllNotification = () => {
		api.putMarkReadAllNotification().then(() => this.props.fetchingNotification());
	}

	render() {
		let { notification } = this.state;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-12d9d170="" data-v-b0e45e8c="" className="notification-page">
						<div data-v-12d9d170="" className="mainLeaderboard">
							<h2 data-v-12d9d170="" className="notification-page-title">
								Thông báo
								<span data-v-12d9d170="" onClick={this.onReadAllNotification} className="notification-page-readall"><span data-v-12d9d170=""><svg
									data-v-12d9d170="" xmlns="http://www.w3.org/2000/svg" width="38" height="21"
									viewBox="0 0 38 21"><g data-v-12d9d170="" id="Group_21335" data-name="Group 21335"
														   transform="translate(-917 -81)"><rect data-v-12d9d170=""
																								 id="Rectangle_4940"
																								 data-name="Rectangle 4940"
																								 width="38" height="21"
																								 rx="3"
																								 transform="translate(917 81)"
																								 fill="#878098"/> <g
									data-v-12d9d170="" id="check-all" transform="translate(924.785 83.931)"><path
									data-v-12d9d170="" id="Path_35112" data-name="Path 35112"
									d="M4.773,18.9,0,14.123l1.3-1.3,3.91,2.607L14.336,5l1.3.652L6.757,18.727a1.3,1.3,0,0,1-1.985.168Z"
									transform="translate(0 -5)" fill="#fefefe"/> <path data-v-12d9d170=""
																					   id="Path_35113"
																					   data-name="Path 35113"
																					   d="M28.91,10.3H25.652a.652.652,0,1,1,0-1.3H28.91a.652.652,0,1,1,0,1.3Z"
																					   transform="translate(-8.709 -6.393)"
																					   fill="#fefefe"/> <path
									data-v-12d9d170="" id="Path_35114" data-name="Path 35114"
									d="M27.516,17.3H21.652a.652.652,0,0,1,0-1.3h5.865a.652.652,0,1,1,0,1.3Z"
									transform="translate(-7.316 -8.832)" fill="#fefefe"/> <path data-v-12d9d170=""
																								id="Path_35115"
																								data-name="Path 35115"
																								d="M25.774,24.3H16.652a.652.652,0,0,1,0-1.3h9.123a.652.652,0,0,1,0,1.3Z"
																								transform="translate(-5.574 -11.271)"
																								fill="#fefefe"/></g></g></svg></span> </span>
							</h2>
							<div data-v-12d9d170="" className="notification-page-body">
								<NotificationComponent list={notification} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		notificationReducer: state.notificationReducer,
	};
}

export default connect(mapStateToProps, {
	fetchingNotification,
	fetchingNotificationSuccess
})(withRouter(Notification));

