import {setClassNameCondition} from "../../helper";
import api from '../../api';

import { Component } from 'react';
import {connect} from "react-redux";
import {
	fetchingNotification,
} from "../../../redux/actions";
import {withRouter} from "react-router-dom";

class Item extends Component{

	onRead = () => {
		api.getNotificationDetail(this.props.item.id).then(({ success }) => {
			success && this.props.fetchingNotification();
		});
	}

	render() {
		let { is_read, link, content, title, created_at }  = this.props.item;
		return <li onClick={this.onRead} className={setClassNameCondition(is_read, 'notification-item--readed', '', 'notification-item')}>
			<div>
				<h4 className="notification-item-title">{title}</h4>
				<p className="notification-item-text">
					{content}
					{link && <a href={link} >Đọc thêm.</a>}
				</p>
				<span className="notification-item-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="32.676" height="32.824" viewBox="0 0 32.676 32.824">
					<g id="notification" transform="translate(0 8.942) rotate(-19)">
					<path id="Path_35326" data-name="Path 35326"
						  d="M.859,0A3.326,3.326,0,0,0,.367.05,14.9,14.9,0,0,0,0,3.433,14.9,14.9,0,0,0,.367,6.816a3.326,3.326,0,0,0,.492.05A3.433,3.433,0,1,0,.859,0Z"
						  transform="translate(19.742 6.866)" fill="#2077fc"/>
					<path id="Path_35327" data-name="Path 35327"
						  d="M6.8,2.259,0,0,3.389,8.333a2.723,2.723,0,0,0,2.525,1.7,2.7,2.7,0,0,0,1.009-.2A2.722,2.722,0,0,0,8.428,6.3Z"
						  transform="translate(6.4 17.429)" fill="#2077fc"/>
					<path id="Path_35328" data-name="Path 35328"
						  d="M21.888,0a3.288,3.288,0,0,0-1.2.235V.228L3.232,6.032A4.233,4.233,0,0,0,0,10.262a4.235,4.235,0,0,0,3.162,4.283l17.521,5.823v-.007a3.288,3.288,0,0,0,1.2.235c3.128,0,5.578-4.524,5.578-10.3S25.016,0,21.888,0Zm0,18.88c-1.826,0-3.862-3.525-3.862-8.582s2.036-8.582,3.862-8.582S25.75,5.241,25.75,10.3,23.714,18.88,21.888,18.88Z"
						  transform="translate(0 0)" fill="#fefefe"/>
					</g>
				</svg>
			</span>
				<span className="notification-item-status"/>
			</div>
			<span className="notification-item-time">{created_at}</span>
		</li>
	}
}

export default connect(null, {
	fetchingNotification
})(withRouter(Item));
