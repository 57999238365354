import { combineReducers } from 'redux';
import chartReducer from './chartReducer';
import memberReducer from './memberReducer';
import appReducer from './appReducer';
import transactionReducer from './transactionReducer';
import notificationReducer from './notificationReducer';

const rootReducer = combineReducers({
	chartReducer,
	memberReducer,
	appReducer,
	transactionReducer,
	notificationReducer
});

export default rootReducer;
