import { all, fork } from 'redux-saga/effects';
import * as userSaga from './userSaga';
import * as configSaga from './configSaga';
import * as transactionSaga from './transactionSaga';
import * as notificationSaga from './notificationSaga';

export default function* rootSaga() {
  yield all([
    ...Object.values(userSaga),
    ...Object.values(configSaga),
    ...Object.values(transactionSaga),
    ...Object.values(notificationSaga),
  ].map(fork))
}
