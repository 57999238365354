import { createReducer } from 'redux-act';
import { fetchingNotificationSuccess } from "../actions";

export default createReducer({
	[fetchingNotificationSuccess]: (state, payload) => {
		return {...state, ...payload};
	},
}, {
	un_read_count: 0,
	notification: [],
	limit: 10,
	page: 1,
	total: 0
});
